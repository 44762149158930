const blockSlider = {
	
	ui: {},

	blockSlider: false,

	init: function() {
		this.bindUI();
		this.bindEvents();
		
		this.initNewsSlider();
		this.paginationPosition();
	},

	bindUI: function() {
		this.ui.$window = $(window);
		
		this.ui.$slideInfos = $('.js-blockSliderSlide-infos');
		this.ui.$slideVisual = $('.js-blockSliderSlide-visual');
	},

	bindEvents: function() {
		this.ui.$window.on('resize', this.paginationPosition.bind(this));
	},

	paginationPosition: function() {
		let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
		if (viewportWidth < 980) {
			setTimeout( () => {
				$('.js-block-slider-slide.active .js-blockSliderSlide-infos').each(function(){
					let $sliderPagination = $(this).closest('.js-block-slider-wrapper').find('.js-block-slider-pagination');
					let slideInfosHeight = $(this).outerHeight();
					$sliderPagination.css('top', (slideInfosHeight - 26) + 'px');
				});
			}, 500);
		}
	},

	initNewsSlider: function() {
		$('.js-block-slider-container').each((index, element) => {
			let $swiperBlock = $(element).find('.js-block-slider');
			$swiperBlock.Swiper({
				speed: 400,
				autoHeight: true,
				containerModifierClass: 'js-block-slider',
				wrapperClass: 'js-block-slider-wrapper',
				slideClass: 'js-block-slider-slide',
				slideActiveClass: 'active',
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				},
				pagination: {
					el: '.js-block-slider-pagination',
					type: 'fraction',
					renderFraction: function (currentClass, totalClass) {
						return '<span class="sliderPagination__current ' + currentClass + '"></span>' +
							'<span class="sliderPagination__total">/<span class="' + totalClass + '"></span></span>';
					},
					formatFractionCurrent: function (number) {
						if (number < 10) return '0'+number;
						return number;
					},
					formatFractionTotal: function (number) {
						if (number < 10) return '0'+number;
						return number;
					}
				},
				navigation: {
					nextEl: $(element).find('.js-block-slider-prev'),
					prevEl: $(element).find('.js-block-slider-next'),
				},
				breakpoints: {
					// when window width is >= 980
					980: {
						autoHeight: false
					}
				},
				on: {
					slideChange: () => {
						this.paginationPosition();
					}
				}
			});
		});
	}
};

module.exports = blockSlider;