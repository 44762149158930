import { createBrowserHistory } from '../../../gulp/node_modules/history';
let history = createBrowserHistory();

import timeline from './timeline.js';

const documentsList = {
	
	ui: {},
	
	filterSlug: '',

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.filterSlug = this.ui.$documentsList_filter.attr('data-filter-slug');
		
		let unlisten = history.listen(({ location, action }) => {
			// If action is POP it means we navigate (prev,next) to an history.push() location
			if (action === 'POP') {
				window.location.reload();
			}
		});

		this.checkIfToggleTimeline();
	},

	bindUI: function() {
		this.ui.$window = $(window);

		this.ui.$adherentArea_list = $('.js-adherentArea-list');

		this.ui.$timelineToggle_close = $('.js-timelineToggle-close');
		
		this.ui.$documentsList = $('.js-documentsList');
		this.ui.$documentsList_counter = $('.js-documentsList-counter');
		this.ui.$documentsList_filter = $('.js-documentsListFilter');
		this.ui.$documentsList_subFilter = $('.js-documentsListFilter-subFilter');
		
		this.ui.$documentsList_radio = $('.js-documentsListFilter-radio');
		
		this.ui.$documentsList_search = $('.js-documentsListSearch');

		this.ui.$documentsList_loader = $('.js-documentsList-loader');
	},

	bindEvents: function() {
		this.ui.$documentsList_radio.on('change', this.filterList.bind(this));
		
		this.ui.$documentsList_search.on('input', this.searchList.bind(this));

		this.ui.$window.on('resize', this.checkIfToggleTimeline.bind(this));
	},

	checkIfToggleTimeline: function() {
		let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
		if (viewportWidth < 1024) {
			if (this.ui.$adherentArea_list.hasClass('adherentArea__list--sidebar-visible')) {
				this.ui.$timelineToggle_close.trigger('click');
			}
		}
	},

	searchList: function(e) {
		let $this = $(e.currentTarget);
		let searchValue = $this.val();

		/*accentsTidy = function(s){
			var r=s.toLowerCase();
			r = r.replace(new RegExp("\\s", 'g'),"");
			r = r.replace(new RegExp("[àáâãäå]", 'g'),"a");
			r = r.replace(new RegExp("æ", 'g'),"ae");
			r = r.replace(new RegExp("ç", 'g'),"c");
			r = r.replace(new RegExp("[èéêë]", 'g'),"e");
			r = r.replace(new RegExp("[ìíîï]", 'g'),"i");
			r = r.replace(new RegExp("ñ", 'g'),"n");
			r = r.replace(new RegExp("[òóôõö]", 'g'),"o");
			r = r.replace(new RegExp("œ", 'g'),"oe");
			r = r.replace(new RegExp("[ùúûü]", 'g'),"u");
			r = r.replace(new RegExp("[ýÿ]", 'g'),"y");
			r = r.replace(new RegExp("\\W", 'g'),"");
			return r;
		};*/
		
		if (searchValue.length > 1) {
			// remove whitespace from both ends of string
			// escape caracters which are used in a regex
			// replace multiple whitespace by a single one
			// replace single whitespace by a pipe to search different terms in the regex
			let searchTerms = searchValue.trim().replace("’", "'").replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&').replace(/\s\s+/g, ' ').replace(' ', '|');
			
			let regex = new RegExp("(^|\\s)"+searchTerms+"(?=\\s|$|.)", "gi");
			$('.js-documentsList-item').each(function(){
				let documentTitle = $(this).find('.js-documentsList-documentTitle').text().replace("’", "'");
				if (documentTitle.search(regex) >= 0) {
					$(this).removeClass('is-hidden');
				} else {
					$(this).addClass('is-hidden');
				}
			});
		} else {
			$('.js-documentsList-item').removeClass('is-hidden');
		}
	},
	
	filterList: function(e) {

		let filters_exists = ($('.js-documentsListFilter-radio').length > 0);

		let $currentFilter_parent = $('.js-documentsListFilter-radio[name=current_filter_type_parent]:checked');
		let $currentFilter_child = $('.js-documentsListFilter-radio[name=current_filter_type_child]:checked');
		let $currentFilter = $currentFilter_parent;

		if (e) {
			$currentFilter = $(e.currentTarget);
		} else if ($currentFilter_child.length > 0) {
			$currentFilter = $currentFilter_child;
		} else if ($currentFilter_parent.length > 0) {
			$currentFilter = $currentFilter_parent;
		} else if (filters_exists) {
			// if we're here it's because we don't have any radio button checked
			// it's not possible but a secure bail out is safer
			// IT IS POSSIBLE MORRON ! On a commission page we don't have those filters anymore :'(
			// must add an extra check if the radio fields exists
			return false;
		}
		
		// Only add history.push() before we check if we go through Timeline function or not
		// We add the loader class in the timeline module so here we do it after this check to avoid errors
		if(!this.ui.$documentsList_loader.hasClass('is-loading')){
			if (filters_exists) {
				// Add the filter to the url, so we can refresh and keep the filter
				history.push('?'+this.filterSlug+'='+$currentFilter.val());
			}
		} else {
			return false;
		}

		// We empty the search text field when we set a new taxonomy filter
		this.ui.$documentsList_search.val('');
		
		
		// If it's a parent nav item we have to display the child items
		if ($currentFilter.attr('name') === 'current_filter_type_parent' && filters_exists) {
			this.ui.$documentsList_subFilter.removeClass('is-active').find('input').prop('checked', false);
			$('.js-documentsListFilter-subFilter[data-parent-slug='+$currentFilter.val()+']').addClass('is-active');
		}

		if (this.ui.$adherentArea_list.hasClass('adherentArea__list--sidebar-visible')) {
			// If timeline panel is opened we filter with timeline function
			timeline.updateDocumentsList();
			return false;
		}

		// See first comment in filterList() function -> we add the loader class here
		this.ui.$documentsList_loader.addClass('is-loading');
		
		let formDatas = $('.js-form-filterList-formDatas')
			.filter(function (index, element) {
				return $(element).val() !== ''
			})
			.serialize();

		// Clear everything in the list to add new content after
		$('.js-documentsList-item').remove();
		$('.js-documentsList-empty').remove();
		timeline.updatePageTitle();
		
		$.ajax({
			url: template_vars.ajax_url,
			type: 'POST',
			data: formDatas,
			success: (result) => {
				let $result = $( result );
				
				this.ui.$documentsList_loader.removeClass('is-loading');

				this.ui.$documentsList.append($result);

				this.ui.$documentsList_counter.html(this.ui.$documentsList.find('.js-documentsList-item').length);
			},
			error: () => {
				this.ui.$documentsList_loader.removeClass('is-loading').addClass('is-error');
				this.ui.$documentsList_counter.html('0');
			},
		})
	},
};

module.exports = documentsList;