const manageTitles_collect = {
	
	ui: {},
	
	currentMemberID: 0,

	init: function() {
		this.bindUI();
		this.bindEvents();
		
		this.bindInputsCalculation();
		
		this.currentMemberID = $('.js-manageTitles-member_id').val();

		this.updateAllEvolutions();

		this.updateSectionTitleDisplay();
	},

	bindUI: function() {
		this.ui.$selectPreviousDate = $('.js-manageTitles-select-previous-date');
		this.ui.$previousMonthValue = $('.js-manageTitles-previousMonthValue');
		this.ui.$tableLine = $('.js-manageTitles-tableLine');
		this.ui.$currentMonthInput = $('.js-manageTitles-currentMonthInput');

		this.ui.$tableHeadControl = $('.js-manageTitles-tableHeadControl');
		
		this.ui.$fieldDisplayType = $('.js-manageTitles-fieldDisplayType');

		this.ui.$inputCalculation = $('.js-manageTitles-inputCalculation');

		this.ui.$importCollectDatas = $('.js-manageTitles-importCollectDatas');

		this.ui.$toggleSupplement = $('.js-manageTitles-toggleSupplement');
	},

	bindEvents: function() {
		this.ui.$selectPreviousDate.on('change', this.selectPreviousDate.bind(this));

		this.ui.$fieldDisplayType.on('change', this.changeFieldDisplayType.bind(this));

		this.ui.$currentMonthInput.on('input', this.updateLineEvolution.bind(this));

		this.ui.$importCollectDatas.on('input', this.submitImportForm.bind(this));

		this.ui.$toggleSupplement.on('change', this.changeSupplementTypeDisplay.bind(this));
	},

	submitImportForm: function(e) {
		let $this = $(e.currentTarget);
		let $form = $this.closest('form');
		$form.trigger('submit');
	},

	bindInputsCalculation: function() {
		// Here we set up all the calculation we made in the json of datas
		// it's complexe but this is so we do not put hard coded field names and calculations
		this.ui.$inputCalculation.each((index, element) => {
			let $this = $(element);
			let calculation = JSON.parse($this.attr('data-calculation'));

			if (calculation.operator === '+') {
				calculation.keys.forEach((element) => {
					$('#' + element).on('input', function () {
						let total = 0;
						calculation.keys.forEach((element) => {
							let value = parseFloat($('#' + element).val().trim().replace(/ /g, '').replace(/,/g, '.'));
							if (isNaN(value)) value = 0;
							total = total + value;
						});
						$this.val(Math.round(total)).trigger('input');
					});
				});
			}

			if (calculation.operator === '/') {
				calculation.keys.forEach((element) => {
					$('#' + element).on('input', function () {
						let value_1 = parseFloat($('#' + calculation.keys[0]).val().trim().replace(/ /g, '').replace(/,/g, '.'));
						let value_2 = parseFloat($('#' + calculation.keys[1]).val().trim().replace(/ /g, '').replace(/,/g, '.'));
						if (isNaN(value_1)) value_1 = 0;
						if (isNaN(value_2)) value_2 = 0;

						let rate = Math.round((value_1 / value_2) * 1000) / 10;
						// If we divide by 0 it's infinite, just set 0 to be safe.
						if (!isFinite(rate)) rate = 0;
						$this.val(rate).trigger('input');
					});
				});
			}

			if (calculation.operator === 'average') {
				calculation.keys.forEach((element) => {
					$('#' + element).on('input', function () {
						let total = 0;
						let values_count = 0;
						calculation.keys.forEach((element) => {
							let value = parseFloat($('#' + element).val().trim().replace(/ /g, '').replace(/,/g, '.'));
							if (isNaN(value)) value = 0;
							if (value !== 0) values_count++;
							total = total + value;
						});
						total = Math.round((total / values_count) * 100) / 100;
						$this.val(total).trigger('input');
					});
				});
			}

			if (calculation.operator === 'accumulation') {
				// When we do an accumulation we're supposed to have 4 keys
				// key 0 is the 7day field
				// key 1 is the week field
				// key 2 is the nombre_parutions_7 field
				// key 3 is the nombre_parutions field
				calculation.keys.forEach((element) => {
					$('#' + element).on('input', function () {
						let value_7th_day = parseFloat($('#' + calculation.keys[0]).val().trim().replace(/ /g, '').replace(/,/g, '.'));
						let value_week = parseFloat($('#' + calculation.keys[1]).val().trim().replace(/ /g, '').replace(/,/g, '.'));
						let parutions_7 = parseFloat($('#' + calculation.keys[2]).val().trim().replace(/ /g, '').replace(/,/g, '.'));
						let parutions = parseFloat($('#' + calculation.keys[3]).val().trim().replace(/ /g, '').replace(/,/g, '.'));
						if (isNaN(value_7th_day)) value_7th_day = 0;
						if (isNaN(value_week)) value_week = 0;
						if (isNaN(parutions_7)) parutions_7 = 0;
						if (isNaN(parutions)) parutions = 0;

						let total = Math.round( ( (value_week*parutions) + (value_7th_day*parutions_7) ) / (parutions+parutions_7) );
						$this.val(total).trigger('input');
					});
				});
			}
		});
	},
	
	changeFieldDisplayType: function(e) {
		let $this = $(e.currentTarget);
		let value = $this.val();
		
		if (value === 'field_accumulation') {
			this.ui.$tableHeadControl.addClass('manageTitles__head--hidden');
		} else {
			this.ui.$tableHeadControl.removeClass('manageTitles__head--hidden');
		}

		$('.js-manageTitles-dataColumnTitle').addClass('hidden');
		$('.js-manageTitles-dataColumnTitle[data-field-type='+value+']').removeClass('hidden');

		$('.js-manageTitles-tableLine').addClass('manageTitles__tableContent__line--hidden');
		$('.js-manageTitles-tableLine[data-field-type='+value+']').removeClass('manageTitles__tableContent__line--hidden');

		this.updateSectionTitleDisplay();
	},

	changeSupplementTypeDisplay: function(e) {
		let $this = $(e.currentTarget);
		let base_key = $this.attr('data-base-key');
		let is_checked = e.currentTarget.checked;
		let price_type, other_price_type;

		if (is_checked) {
			price_type = $this.attr('data-if-true');
			other_price_type = $this.attr('data-if-false');
		} else {
			price_type = $this.attr('data-if-false');
			other_price_type = $this.attr('data-if-true');
		}

		let $tableLines_all = $('.js-manageTitles-tableLine[data-base-key='+base_key+'][data-supplement-type]');
		let $tableLines_target = $('.js-manageTitles-tableLine[data-base-key='+base_key+'][data-supplement-type='+price_type+']');
		let $tableLines_previous = $('.js-manageTitles-tableLine[data-base-key='+base_key+'][data-supplement-type='+other_price_type+']');
		let $previous_input = $tableLines_previous.find('.js-manageTitles-currentMonthInput');
		let $target_input = $tableLines_target.find('.js-manageTitles-currentMonthInput');

		// Hide both line for both values + toggle the same way for the input in both lines, they're supposed to be the same checkbox
		$tableLines_all
			.addClass('manageTitles__tableContent__line--hidden')
			.find('.js-manageTitles-toggleSupplement').prop('checked', is_checked);

		// Keep field value when we change the line
		$target_input.val($previous_input.val()).trigger('input');
		// Then clear field in the other line value
		// Then trigger the input, so it calculates again
		$previous_input.val('').trigger('input');

		// Display the line we want
		$tableLines_target.removeClass('manageTitles__tableContent__line--hidden');

		// Setup the toggle value in a hidden field
		$('.js-manageTitles-tableLine[data-field-type=text][data-base-key='+base_key+']')
			.find('.js-manageTitles-currentMonthInput').val(price_type);
	},

	updateSectionTitleDisplay: function() {
		$('.js-manageTitles-tableLineSectionTitle').removeClass('manageTitles__tableContent__line--hidden').each(function(){
			let $next = $(this).nextAll(':not(.manageTitles__tableContent__line--hidden)');

			if ($next.length === 0 || $($next[0]).hasClass('js-manageTitles-tableLineSectionTitle')) {
				$(this).addClass('manageTitles__tableContent__line--hidden');
			}
		})
	},

	selectPreviousDate: function(e) {
		let $this = $(e.currentTarget);

		// Update val because we have multiple select like this
		// and we want all of them on the same value
		this.ui.$selectPreviousDate.val($this.val());

		this.ui.$previousMonthValue.text('-');
		
		$.ajax({
			url: template_vars.ajax_url,
			type: 'POST',
			data: {
				'action': 'get_title_month_collect_datas',
				'collect_id': $this.val(),
				'title_id': this.currentMemberID
			},
			success: (response) => {
				let title_month_collect_datas = JSON.parse(response);

				Object.entries(title_month_collect_datas).forEach(([key, value]) => {
					let key_split = key.split('_');

					value = parseFloat(value);
					if (isNaN(value)) {
						value = '-';
					} else {
						value = value.toLocaleString('en-US').trim().replace(/,/g, ' ');
					}

					let value_not_assigned = true;

					if (key_split.length > 3) {
						let is_supp_type_field = key.substring(key.length - 5) === '_type';
						let is_prix_vente_field = (key_split[0] === 'prix' && key_split[1] === 'vente');
						let supp_base_key = key_split[0]+'_'+key_split[1]+'_'+key_split[2];

						if (!is_supp_type_field && is_prix_vente_field) {
							if (value !== '0' && value !== '-') {
								value_not_assigned = false;
								let value_price = parseFloat(value);
								value_price = value_price.toFixed(2).replace(/.00/g, '');

								$('.js-manageTitles-tableLine[data-base-key='+supp_base_key+']').find('.js-manageTitles-previousMonthValue').text(value_price).attr('data-value', value_price);
							}
						}
					}

					if (value_not_assigned) {
						$('.js-manageTitles-previousMonthValue-key_'+key).text(value).attr('data-value', value);
					}

				});

				this.updateAllEvolutions();
			},
			error: (response) => {
				console.log(response);

				this.updateAllEvolutions();
			},
		})
	},

	calculateLineEvolution: function($line) {
		let $currentMonth = $line.find('.js-manageTitles-currentMonthValue');
		let currentMonth_content = $currentMonth.text();
		if ($currentMonth.hasClass('js-manageTitles-currentMonthValue--input')) {
			currentMonth_content = $currentMonth.find('.js-manageTitles-currentMonthInput').val();
		}

		let $previousMonth = $line.find('.js-manageTitles-previousMonthValue');
		let $evolution = $line.find('.js-manageTitles-evolution');
		let $evolutionValue = $line.find('.js-manageTitles-evolutionValue');
		let currentMonth_val = parseFloat(currentMonth_content.trim().replace(/ /g, '').replace(/,/g, '.'));
		let previousMonth_val = parseFloat($previousMonth.attr('data-value').trim().replace(/ /g, '').replace(/,/g, '.'));

		$evolution.removeClass('manageTitles__evol--hidden manageTitles__evol--up manageTitles__evol--down');

		if (isNaN(currentMonth_val)) {
			currentMonth_val = 0;
		}
		if (isNaN(previousMonth_val)) {
			previousMonth_val = 0;
		}

		if (currentMonth_val === 0 || previousMonth_val === 0) {
			$evolution.addClass('manageTitles__evol--hidden');
			$evolutionValue.text('-');
		} else {
			let evol = ((currentMonth_val / previousMonth_val) * 100) - 100;
			if (evol > 0) {
				$evolution.addClass('manageTitles__evol--up');
			} else if (evol < 0) {
				$evolution.addClass('manageTitles__evol--down');
			} else {
				$evolution.addClass('manageTitles__evol--hidden');
			}
			$evolutionValue.text(evol.toFixed(1));
		}
	},

	updateAllEvolutions: function() {
		this.ui.$tableLine.each((index, element) => {
			this.calculateLineEvolution($(element));
		});
	},

	updateLineEvolution: function(e) {
		let $this = $(e.currentTarget);

		$this.val($this.val().replace(/[^0-9 .,]/g, ''));

		this.calculateLineEvolution($this.closest('.js-manageTitles-tableLine'));
	}
};

module.exports = manageTitles_collect;