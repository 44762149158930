const sortableTable = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$sortableTable = $('.js-sortableTable');
		this.ui.$ST_head = $('.js-sortableTable-head');
		this.ui.$ST_headCell = $('.js-sortableTable-headCell');
		this.ui.$ST_content = $('.js-sortableTable-content');
		this.ui.$ST_contentLine = $('.js-sortableTable-contentLine');
		this.ui.$ST_contentLineCell = $('.js-sortableTable-contentLineCell');
	},

	bindEvents: function() {
		this.ui.$ST_headCell.on('click', this.updateSortingDisplay.bind(this));
	},

	updateSortingDisplay: function (e) {
		let $this = $(e.currentTarget);
		if ($this.hasClass('is-active')) {
			if ($this.hasClass('sort-desc')) {
				$this.removeClass('sort-desc').addClass('sort-asc');
			} else {
				$this.removeClass('sort-asc').addClass('sort-desc');
			}
		} else {
			$('.js-sortableTable-headCell.is-active').removeClass('is-active sort-desc sort-asc');
			$this.addClass('is-active sort-desc');
		}
		
		this.sortTable($this.closest('.js-sortableTable'));
	},

	sortTable: function ($table) {
		let $headCell = $table.find('.js-sortableTable-headCell.is-active');
		let $tableContent = $table.find('.js-sortableTable-content.is-active');

		let sort_type = $headCell.attr('data-sort-type');
		let order = 'asc';
		if ($headCell.hasClass('sort-desc')) {
			order = 'desc';
		}
		
		let col_index = Array.from($headCell[0].parentNode.children).indexOf($headCell[0]);

		Array.from($tableContent.find('.js-sortableTable-contentLine')).sort( (a, b) => {
			let $1 = $(b).find('.js-sortableTable-contentLineCell').eq(col_index);
			let $2 = $(a).find('.js-sortableTable-contentLineCell').eq(col_index);
			let attr1 = $1.attr('data-sortable-'+sort_type);
			let attr2 = $2.attr('data-sortable-'+sort_type);
			let v1, v2;

			if (order === 'asc') {
				v1 = attr1;
				v2 = attr2;
			} else {
				v1 = attr2;
				v2 = attr1;
			}

			if (sort_type === 'text') {
				return v1.toString().localeCompare(v2);
			} else {
				return v1 - v2;
			}
		}).forEach(line => {
			$tableContent.append(line);
		});
	}
};

module.exports = sortableTable;