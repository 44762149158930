const slidingTitles = {
	
	ui: {},

	colors: [
		'#2E248C',
		'#66C9CC',
		'#D45398',
		'#2E248C',
		'#66C9CC',
		'#D45398'
	],

	init: function() {
		this.bindUI();
		
		this.initSliding();
	},

	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$slidingTitles = $('.js-sliding-titles');
		this.ui.$row = $('.js-sliding-titles-row');
	},

	initSliding: function() {
		let colors = this.colors;

		this.ui.$row.each(function (index) {

			let $thisRow = $(this),
				$titles = $thisRow.find('li'),
				titleCount = $titles.length;

			$titles.each(function (index) {
				$(this).css('color', colors[Math.floor(Math.random() * colors.length)]);
			});

			// Must delay the animation reset because safari seems to have buggy anim rendering
			if (window.isSafari) {
				setTimeout(function(){
					if ($thisRow.hasClass('anim-ltr')) {
						$thisRow.removeClass('anim-ltr');
						setTimeout(function(){
							$thisRow.addClass('anim-ltr');
						}, 100);
					}
					if ($thisRow.hasClass('anim-rtl')) {
						$thisRow.removeClass('anim-rtl');
						setTimeout(function(){
							$thisRow.addClass('anim-rtl');
						}, 100);
					}
				}, 100);
			}

			// Adjust Transition speed
			// row.querySelector('ul').style.animationDuration = $titleCount*7.5 + 's';
			// We do this in the template (before DOM loads) because of weird behavior in IE Edge
			/*$(this).find('ul').css({
				'-webkit-animation-duration': titleCount * 7.5 + 's'
			});*/
		});
	},
};

module.exports = slidingTitles;