const timeline = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$window = $(window);
		
		this.ui.$pageTitle = $('.js-pageTitle');
		
		this.ui.$timelineItem = $('.js-timelineItem');

		this.ui.$documentsList = $('.js-documentsList');
		this.ui.$documentsList_counter = $('.js-documentsList-counter');
		this.ui.$documentsList_loader = $('.js-documentsList-loader');

		this.ui.$hiddenField_meetingID = $('.js-form-timelineDocuments-formDatas[name=meeting_id]');
	},

	bindEvents: function() {
		this.ui.$timelineItem.on('click', this.updateTimeline.bind(this));
	},

	updatePageTitle: function($newPageTitle = []) {
		if (this.ui.$pageTitle === undefined) {
			this.ui.$pageTitle = $('.js-pageTitle');
		} 
		
		// If we don't have an input with a title value we get the default title on his attribute
		if ($newPageTitle.length !== 0) {
			this.ui.$pageTitle.text($newPageTitle.val());
		} else {
			this.ui.$pageTitle.text(this.ui.$pageTitle.attr('data-page-title'));
		}
	},

	updateTimeline: function(e) {
		e.stopPropagation();
		let $this = $(e.currentTarget);

		// If it's not loading, and if it's not the same timeline item clicked
		if((!this.ui.$documentsList_loader.hasClass('is-loading')) && (!$this.hasClass('is-active'))){
			this.ui.$timelineItem.removeClass('is-active');
			$this.addClass('is-active');
			
			this.updateDocumentsList($this);
		}
	},
	
	updateDocumentsList: function($activeTimelineItem = $('.js-timelineItem.is-active')) {
		// Don't go through the function if no timeline item is active
		if ($activeTimelineItem.length === 0) return false;

		if(!this.ui.$documentsList_loader.hasClass('is-loading')){
			this.ui.$documentsList_loader.addClass('is-loading');
		} else {
			return false;
		}

		this.ui.$hiddenField_meetingID.val($activeTimelineItem.attr('data-meeting-id'));
		
		let formDatas = $('.js-form-timelineDocuments-formDatas')
			.filter(function (index, element) {
				return $(element).val() !== ''
			})
			.serialize();
		
		// Clear everything in the list to add new content after
		$('.js-documentsList-item').remove();
		$('.js-documentsList-empty').remove();
		$('.js-pageTitle-update').remove();
		
		$.ajax({
			url: template_vars.ajax_url,
			type: 'POST',
			data: formDatas,
			success: (result) => {
				let $result = $( result );
				
				this.ui.$documentsList_loader.removeClass('is-loading');

				this.ui.$documentsList.append($result);

				this.ui.$documentsList_counter.html(this.ui.$documentsList.find('.js-documentsList-item').length);
				
				this.updatePageTitle(this.ui.$documentsList.find('.js-pageTitle-update'));
			},
			error: () => {
				this.ui.$documentsList_loader.removeClass('is-loading').addClass('is-error');
				this.ui.$documentsList_counter.html('0');
				this.updatePageTitle();
			},
		})
	},
};

module.exports = timeline;