import { createBrowserHistory } from '../../../gulp/node_modules/history';
let history = createBrowserHistory();

const newsList = {
	
	ui: {},

	listTotal: 0,
	
	filterSlug: '',
	
	masonryOptions: {
		itemSelector: '.js-newsListMasonry-item',
		gutter: '.js-newsListMasonry-gutter',
		columnWidth: '.js-newsListMasonry-sizer',
		horizontalOrder: true,
		percentPosition: true,
		initLayout: true,
		transitionDuration: 0
	},
	masonryIsActive: false,

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.listTotal = $('.js-ajax-list-total').val();
		this.filterSlug = this.ui.$newsListFilter.attr('data-filter-slug');
		
		this.initNewsSlider();
		this.initNewsListMasonry();
	},

	bindUI: function() {
		this.ui.$window = $(window);

		this.ui.$newsSlider = $('.js-news-slider');

		this.ui.$newsListFilter = $('.js-newsListFilter');
		this.ui.$newsListFilterLoader = $('.js-newsListFilter-loader');
		this.ui.$newsListMasonry = $('.js-newsListMasonry');
		this.ui.$loadMoreButton = $('.js-newsList-loadMore');
		this.ui.$ajaxFilterInput = $('.js-ajax-filter-input');
	},

	bindEvents: function() {
		this.ui.$newsListFilter.on('change', this.filterList.bind(this));
		this.ui.$loadMoreButton.on('click', this.loadMoreNews.bind(this));
		
		this.ui.$window.on('resize', this.responsiveMasonry.bind(this));
	},

	responsiveMasonry: function() {
		let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

		if (viewportWidth < 980) {
			if (this.masonryIsActive) {
				this.masonryIsActive = false;
				this.ui.$newsListMasonry.masonry('destroy');
			}
		} else {
			if (!this.masonryIsActive) {
				this.masonryIsActive = true;
				this.ui.$newsListMasonry.masonry(this.masonryOptions);
				$('.js-newsListMasonry-item').css('position', 'absolute');
				this.ui.$newsListMasonry.masonry('layout');
			}
		}
	},

	loadMoreNews: function(e) {
		let $button = $(e.currentTarget);
		let $currentList = $button.closest('.js-ajax-list');
		let $pageInput = $currentList.find('.js-ajax-page-input');
		let currentPage = $pageInput.val();
		let formDatas = $currentList
			.find('.js-form-loadMore')
			.filter(function (index, element) {
				return $(element).val() !== ''
			})
			.serialize();

		if(!$button.hasClass('is-loading')){
			$button.addClass('is-loading');
			$.ajax({
				url: template_vars.ajax_url,
				type: 'POST',
				data: formDatas,
				success: (result) => {
					let $result = $( result );

					currentPage++;
					$pageInput.val(currentPage);
					$button.removeClass('is-loading');
					
					if (this.masonryIsActive) {
						this.ui.$newsListMasonry.append($result).masonry( 'appended', $result );
						imagesLoaded( $('.js-newsListMasonry'), () => {
							this.ui.$newsListMasonry.masonry();
						});
					} else {
						this.ui.$newsListMasonry.append($result);
					}

					if(this.ui.$newsListMasonry.find('.js-newsListMasonry-item').length >= this.listTotal) {
						$button.hide();
					}
				},
				error: function () {
					$button.removeClass('is-loading').addClass('is-error');
				},
			})
		}
	},

	filterList: function(e) {
		let $select = $(e.currentTarget);
		let $currentList = $select.closest('.js-ajax-list');
		let $pageInput = $currentList.find('.js-ajax-page-input');
		let formDatas = $currentList
			.find('.js-form-filterList')
			.filter(function (index, element) {
				return $(element).val() !== ''
			})
			.serialize();

		if(!this.ui.$newsListFilterLoader.hasClass('is-loading')){
			this.ui.$newsListFilterLoader.addClass('is-loading');

			// Add the filter to the url, so we can refresh and keep the filter
			history.push('?'+this.filterSlug+'='+this.ui.$newsListFilter.val());
			
			this.ui.$newsListMasonry.masonry( 'destroy' );
			this.ui.$newsListMasonry.removeData('masonry');
			$('.js-newsListMasonry-item').remove();
			$('.js-newsList-empty').remove();
			$('.js-ajax-list-total').remove();
			
			$.ajax({
				url: template_vars.ajax_url,
				type: 'POST',
				data: formDatas,
				success: (result) => {
					let $result = $( result );
					
					this.ui.$newsListFilterLoader.removeClass('is-loading');

					if (this.masonryIsActive) {
						this.ui.$newsListMasonry.append($result);
						this.ui.$newsListMasonry.masonry(this.masonryOptions);
						imagesLoaded( $('.js-newsListMasonry'), () => {
							this.ui.$newsListMasonry.masonry();
						});
					} else {
						this.ui.$newsListMasonry.append($result);
					}

					// We set the select value to the hidden input we use when we click on the load more button
					this.ui.$ajaxFilterInput.val($select.val());
					this.listTotal = $('.js-ajax-list-total').val();
					$pageInput.val(2);

					if(this.listTotal > 9) {
						this.ui.$loadMoreButton.show();
					} else {
						this.ui.$loadMoreButton.hide();
					}
				},
				error: () => {
					this.ui.$newsListFilterLoader.removeClass('is-loading').addClass('is-error');
				},
			})
		}
	},

	initNewsSlider: function() {
		const newsSlider = new Swiper('.js-news-slider', {
			speed: 650,
			spaceBetween: 25,
			init: false,
			initialSlide: 0,
			slidesPerView: 'auto',
			centeredSlides: true,
			loop: ($('.js-news-slider-slide').length > 2),
			autoplay: {
				delay: 4650,
				disableOnInteraction: false
			},
			containerModifierClass: 'js-news-slider',
			wrapperClass: 'js-news-slider-wrapper',
			slideClass: 'js-news-slider-slide',
			slideActiveClass: 'active',
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				bulletActiveClass: 'active',
				type: 'bullets',
				bulletClass: 'swiper-bullet',
				renderBullet: function (index, className) {
					return '<button class="' + className + '"><span class="swiper-count">' + (index + 1) + '<span class="swiper-progress"></span></span></span></button>';
				}
			},
			breakpoints: {
				// when window width is >= 980
				980: {
					initialSlide: 0,
					centeredSlides: false,
					spaceBetween: 50,
				}
			},
			on: {
				init: function () {
					this.slideTo(0);
				},
			}
		});
		imagesLoaded( $('.js-news-slider'), () => {
			newsSlider.init();
		});
		newsSlider.on('slideChange', function () {
			newsSlider.params.autoplay.delay = 4000;
		});
	},

	initNewsListMasonry: function(){
		let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
		if (viewportWidth >= 980) {
			this.masonryIsActive = true;

			this.ui.$newsListMasonry.masonry(this.masonryOptions);

			imagesLoaded( $('.js-newsListMasonry'), () => {
				setTimeout( () => {
					this.ui.$newsListMasonry.masonry();
				}, 500 );
			});
		}
	},
};

module.exports = newsList;