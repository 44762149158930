import documentsList from './documentsList.js';

const timelineToggle = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$window = $(window);

		this.ui.$adherentArea_list = $('.js-adherentArea-list');
		
		this.ui.$timelineToggle_toggle = $('.js-timelineToggle-toggle');
		this.ui.$timelineToggle_close = $('.js-timelineToggle-close');

		this.ui.$documentsList_loader = $('.js-documentsList-loader');
	},

	bindEvents: function() {
		this.ui.$timelineToggle_toggle.on('click', this.toggleTimeline.bind(this));
		this.ui.$timelineToggle_close.on('click', this.closeTimeline.bind(this));
	},

	toggleTimeline: function(e) {
		e.stopPropagation();
		let $this = $(e.currentTarget);

		// Don't open/close if ajax is loading
		if (this.ui.$documentsList_loader.hasClass('is-loading')) return false;
		
		if (this.ui.$adherentArea_list.hasClass('adherentArea__list--sidebar-visible')) {
			this.ui.$adherentArea_list.removeClass('adherentArea__list--sidebar-visible');
			this.ui.$timelineToggle_toggle.removeClass('is-active');

			documentsList.filterList();
			$('.js-timelineItem.is-active').removeClass('is-active');
		} else {
			this.ui.$adherentArea_list.addClass('adherentArea__list--sidebar-visible');
			$this.addClass('is-active');

			$('.js-timeline').find('.js-timelineItem').first().click();
		}
	},

	closeTimeline: function(e) {
		e.stopPropagation();
		let $this = $(e.currentTarget);

		// Don't open/close if ajax is loading
		if (this.ui.$documentsList_loader.hasClass('is-loading')) return false;
		
		this.ui.$adherentArea_list.removeClass('adherentArea__list--sidebar-visible');
		this.ui.$timelineToggle_toggle.removeClass('is-active');

		documentsList.filterList();
		$('.js-timelineItem.is-active').removeClass('is-active');
	},
};

module.exports = timelineToggle;