const manageTitles = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$selectTitles = $('.js-manageTitles-selectTitles');
		
		this.ui.$selectCollects = $('.js-manageTitles-selectCollects');
	},

	bindEvents: function() {
		this.ui.$selectTitles.select2({
			placeholder: "-",
			width: '\'style\'',
			theme: 'default select2BigSelect',
			//containerCssClass: "select2BigSelect",
			dropdownCssClass: "select2BigSelect__dropdown",
			selectionCssClass: "select2BigSelect__selection"
		});
		this.ui.$selectTitles.on('change', this.loadPage.bind(this));
		
		this.ui.$selectCollects.select2({
			placeholder: "-",
			width: '\'style\'',
			theme: 'default select2BigSelect',
			//containerCssClass: "select2BigSelect",
			dropdownCssClass: "select2BigSelect__dropdown",
			selectionCssClass: "select2BigSelect__selection"
		});
		this.ui.$selectCollects.on('change', this.loadPage.bind(this));
	},

	loadPage: function(e) {
		let $this = $(e.currentTarget);
		window.location = $this.val();
	}
};

module.exports = manageTitles;