const membersTiles = {
	
	ui: {},

	membersList: null,
	
	membersSlider: null,

	init: function() {
		this.membersList = window.membersList;
		
		this.bindUI();
		this.bindEvents();

		this.tilesHandler();
		this.sliderHandler();
	},

	bindUI: function() {
		this.ui.$window = $(window);

		this.ui.$membersTiles = $('.js-membersTiles');
		this.ui.$memberTile = $('.js-memberTile');
		this.ui.$membersTilesScrollbar = $('.js-membersTiles-scrollbar');
	},

	bindEvents: function() {
		this.ui.$window.on('resize', this.sliderHandler.bind(this));
	},

	getViewportWidth: function(){
		return (window.innerWidth || document.documentElement.clientWidth);
	},

	sliderHandler: function (){
		if (this.getViewportWidth() >= 1024) {
			if( this.ui.$membersTiles.attr('class').match(/containerinitialized/g) ){
				this.destroySlider();
			}
		} else {
			this.initSlider();
		}
	},
	
	initSlider: function (){
		// Set Swiper Container class
		this.ui.$membersTiles.addClass('slider-members--container');
		this.ui.$membersTilesScrollbar.removeClass('is-hidden');
	
		this.membersSlider = new Swiper('.js-membersTiles', {
			speed: 400,
			spaceBetween: 10,
			slidesPerView: 'auto',
			freeModeSticky: true,
			containerModifierClass: 'slider-members--container',
			wrapperClass: 'slider-members--wrapper',
			slideClass: 'card--member-tile',
			slideActiveClass: 'active',
			scrollbar: {
				el: '.slider-members--scrollbar',
				draggable: true,
				dragClass: 'slider-members--scrollbar-drag',
				dragSize: 130
			},
		});
	},

	destroySlider: function (){
		// Must save bg-color because swiper remove style when destroyed
		// So we can reassign it after the destroy();
		let save_current_color = this.ui.$memberTile.css('background-color');
		this.membersSlider.destroy();
		this.membersSlider = undefined;
		this.ui.$memberTile.css('background-color', save_current_color);

		this.ui.$membersTilesScrollbar.addClass('is-hidden');
		this.ui.$membersTiles.removeClass('slider-members--container');
	},

	tilesHandler: function () {

		// Shuffled displayed tiles keys
		let random = this.shuffle([0, 1, 2, 3, 4, 5]);
			

		// Indexes for setInterval behaviour
		// Set memberIndex to 7 to skip the starting stuff to avoid duplicates
		let memberIndex = 7,
			tileIndex = 0;

		setInterval(() => {
			// If 6 we reset to 0 to loop again
			if (tileIndex > 5) tileIndex = 0

			// Custom shuffled tileIndex
			let randomTileIndex = random[tileIndex];

			if (memberIndex === this.membersList.length) {
				memberIndex = 0;
			}

			this.updateTile(
				this.ui.$memberTile[randomTileIndex], 
				this.membersList[memberIndex]
			);
			memberIndex++;
			tileIndex++;
		}, 3750)

	},

	updateTile: function (tile, $member) {
		let $tile = $(tile);
		// Setup loading class to hide the tile
		$tile.removeClass('loaded').addClass('loading');

		// Set a timeout to wait for the hiding animation to end (css opacity)
		setTimeout(function () {
			let $tile_title = $tile.find('.js-memberTile-title');
			let $tile_category = $tile.find('.js-memberTile-category');
			let $tile_cover = $tile.find('.js-memberTile-cover');
			let $tile_frequency = $tile.find('.js-memberTile-frequency');
			let $tile_distribution = $tile.find('.js-memberTile-distribution');
			let $tile_group = $tile.find('.js-memberTile-group');
			let $tile_group_value = $tile.find('.js-memberTile-group-value');

			$tile_title.html($member['title']);
			$tile_category.html($member['category']);
			$tile_cover.attr('src', $member['cover']).attr('alt', $member['title']);
			$tile_frequency.html($member['frequency']);
			$tile_distribution.text($member['distribution']);
			$tile_group_value.html($member['group']);
			if ($member['group'] === '') {
				$tile_group.addClass('is-hidden');
			} else {
				$tile_group.removeClass('is-hidden');
			}
			
			$tile.css('background-color', $member['color']);

			imagesLoaded($tile, function () {
				$tile.removeClass('loading');
			}).on('done', function () {
				$tile.addClass('loaded');
			});
		}, 1000);

	},

	shuffle: function (array) {
		let length = array.length, 
			temp, 
			random;

		// While there are elements in the array
		while (length > 0) {
			
			// Pick a random index
			random = Math.floor(Math.random() * length);
			
			// Decrease length by 1
			length--;
			
			// Swap current array item with an other random array item
			temp = array[length];
			array[length] = array[random];
			array[random] = temp;
		}
		
		return array;
	}
};

module.exports = membersTiles;