const newsSingle = {
	
	ui: {},

	originOffsetTop: 0,
	fakeOffsetTop: 0,
	marginOffset: 0,

	init: function() {
		this.bindUI();
		this.bindEvents();
		
		if (this.ui.$fakeSideImage.length > 0) {
			imagesLoaded( $('.js-side-image-origin'), () => {
				this.initFakeSideImage();
			});
		}
	},

	bindUI: function() {
		this.ui.$window = $(window);

		this.ui.$fakeImageContainer = $('.js-fakeImage-container');
		this.ui.$fakeImageParent = $('.js-fakeImage-parent');
		this.ui.$fakeSideImage = $('.js-fake-side-image');
		this.ui.$sideImageOrigin = $('.js-side-image-origin');
		this.ui.$sideImageOriginContainer = $('.js-side-image-origin-container');
	},

	bindEvents: function() {
		this.ui.$window.on('resize', this.initFakeSideImage.bind(this));
	},

	initFakeSideImage: function() {
		this.ui.$fakeSideImage.css('margin-top', '0px');

		let fakeImageContainerWidth = this.ui.$fakeImageContainer.width();
		let fakeImageParentWidth = this.ui.$fakeImageParent.width();
		let fakeOffset = this.ui.$fakeSideImage.offset();
		let originOffset = this.ui.$sideImageOrigin.offset();
		let originContainerWidth = this.ui.$sideImageOriginContainer.width();

		// Because we must set the wysiwyg content to a smaller width than the one where the real image is
		// So with this we reduce the image size so it stays inside the wysiwyg content
		let fixImageWidth = fakeImageContainerWidth - fakeImageParentWidth;

		if (this.originOffsetTop !== originOffset.top || this.fakeOffsetTop !== fakeOffset.top) {
			this.originOffsetTop = originOffset.top;
			this.fakeOffsetTop = fakeOffset.top;
			this.marginOffset = fakeOffset.top - originOffset.top;
		}

		this.ui.$fakeSideImage.css('margin-top', '-'+this.marginOffset+'px');
		
		// Graphic update : header image is no longer going on the right side, 
		// instead it's now in the same inner than the wysiwyg.
		// So we don't need this line anymore.
		//let fakeImageWidth = originContainerWidth - fixImageWidth;
		
		this.ui.$fakeSideImage.width(originContainerWidth);
		this.ui.$fakeSideImage.height(this.ui.$sideImageOrigin.height());
	}
};

module.exports = newsSingle;