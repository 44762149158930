import { createBrowserHistory } from '../../../gulp/node_modules/history';
let history = createBrowserHistory();

const newsAdherentList = {
	
	ui: {},

	listTotal: 0,
	
	filterSlug: '',
	
	masonryOptions: {
		itemSelector: '.js-newsListMasonry-item',
		gutter: '.js-newsListMasonry-gutter',
		columnWidth: '.js-newsListMasonry-sizer',
		horizontalOrder: true,
		percentPosition: true,
		initLayout: true,
		transitionDuration: 0
	},
	masonryIsActive: false,

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.listTotal = $('.js-ajax-list-total').val();
		this.filterSlug = this.ui.$newsListFilter.attr('data-filter-slug');
		
		this.initNewsListMasonry();
	},

	bindUI: function() {
		this.ui.$window = $(window);

		this.ui.$ajaxList = $('.js-ajax-list');
		
		this.ui.$newsSlider = $('.js-news-slider');

		this.ui.$newsListFilter = $('.js-newsListFilter');
		this.ui.$newsListFilterLoader = $('.js-newsListFilter-loader');
		this.ui.$newsListMasonry = $('.js-newsListMasonry');
		this.ui.$loadMoreButton = $('.js-newsList-loadMore');
		this.ui.$ajaxFilterInput = $('.js-ajax-filter-input');
	},

	bindEvents: function() {
		this.ui.$newsListFilter.on('change', this.filterList.bind(this));
		this.ui.$loadMoreButton.on('click', this.loadMoreNews.bind(this));
		
		this.ui.$window.on('resize', this.responsiveMasonry.bind(this));
	},

	responsiveMasonry: function() {
		let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

		if (viewportWidth < 980) {
			if (this.masonryIsActive) {
				this.masonryIsActive = false;
				this.ui.$newsListMasonry.masonry('destroy');
			}
		} else {
			if (!this.masonryIsActive) {
				this.masonryIsActive = true;
				this.ui.$newsListMasonry.masonry(this.masonryOptions);
				$('.js-newsListMasonry-item').css('position', 'absolute');
				this.ui.$newsListMasonry.masonry('layout');
			}
		}
	},

	loadMoreNews: function(e) {
		let $button = $(e.currentTarget);
		let $pageInput = this.ui.$ajaxList.find('.js-ajax-page-input');
		let currentPage = $pageInput.val();
		let formDatas = $('.js-form-loadMore')
			.filter(function (index, element) {
				return $(element).val() !== ''
			})
			.serialize();

		if(!$button.hasClass('is-loading')){
			$button.addClass('is-loading');
			
			$.ajax({
				url: template_vars.ajax_url,
				type: 'POST',
				method: 'POST',
				data: formDatas,
				success: (result) => {
					let $result = $( result );

					currentPage++;
					$pageInput.val(currentPage);
					$button.removeClass('is-loading');
					
					if (this.masonryIsActive) {
						this.ui.$newsListMasonry.append($result).masonry( 'appended', $result );
						imagesLoaded( $('.js-newsListMasonry'), () => {
							this.ui.$newsListMasonry.masonry();
						});
					} else {
						this.ui.$newsListMasonry.append($result);
					}

					if(this.ui.$newsListMasonry.find('.js-newsListMasonry-item').length >= this.listTotal) {
						$button.hide();
					}
				},
				error: function () {
					$button.removeClass('is-loading').addClass('is-error');
				},
			})
		}
	},

	filterList: function(e) {
		let $select = $(e.currentTarget);
		let $pageInput = this.ui.$ajaxList.find('.js-ajax-page-input');
		let formDatas = $('.js-form-filterList')
			.filter(function (index, element) {
				return $(element).val() !== ''
			})
			.serialize();

		if(!this.ui.$newsListFilterLoader.hasClass('is-loading')){
			this.ui.$newsListFilterLoader.addClass('is-loading');

			// Add the filter to the url, so we can refresh and keep the filter
			history.push('?'+this.filterSlug+'='+this.ui.$newsListFilter.val());
			
			this.ui.$newsListMasonry.masonry( 'destroy' );
			this.ui.$newsListMasonry.removeData('masonry');
			$('.js-newsListMasonry-item').remove();
			$('.js-newsList-empty').remove();
			$('.js-ajax-list-total').remove();
			
			$.ajax({
				url: template_vars.ajax_url,
				type: 'POST',
				method: 'POST',
				data: formDatas,
				success: (result) => {
					let $result = $( result );
					
					this.ui.$newsListFilterLoader.removeClass('is-loading');

					if (this.masonryIsActive) {
						this.ui.$newsListMasonry.append($result);
						this.ui.$newsListMasonry.masonry(this.masonryOptions);
						imagesLoaded( $('.js-newsListMasonry'), () => {
							this.ui.$newsListMasonry.masonry();
						});
					} else {
						this.ui.$newsListMasonry.append($result);
					}

					// We set the select value to the hidden input we use when we click on the load more button
					this.ui.$ajaxFilterInput.val($select.val());
					this.listTotal = $('.js-ajax-list-total').val();
					$pageInput.val(2);

					if(this.listTotal > 9) {
						this.ui.$loadMoreButton.show();
					} else {
						this.ui.$loadMoreButton.hide();
					}
				},
				error: () => {
					this.ui.$newsListFilterLoader.removeClass('is-loading').addClass('is-error');
				},
			})
		}
	},

	initNewsListMasonry: function(){
		let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
		if (viewportWidth >= 980) {
			this.masonryIsActive = true;

			this.ui.$newsListMasonry.masonry(this.masonryOptions);

			imagesLoaded( $('.js-newsListMasonry'), () => {
				this.ui.$newsListMasonry.masonry();
			});
		}
	},
};

module.exports = newsAdherentList;