import sortableTable from './sortableTable';

const report = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindSelect2();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$selectTitles = $('.js-report-selectTitles');
		this.ui.$selectCategories = $('.js-report-selectCategories');
		this.ui.$selectReports = $('.js-report-selectReports');

		this.ui.$selectZone = $('.js-report-selectZone');
		this.ui.$selectFieldType = $('.js-report-selectFieldType');
		
		this.ui.$print = $('.js-report-print');
		
		this.ui.$reportPanel = $('.js-reportPanel');
		this.ui.$reportPanel_toggle = $('.js-reportPanel-toggle');
		
		this.ui.$tableFilter = $('.js-report-tableFilter');
		
		this.ui.$exportExcel_form = $('.js-report-exportExcel-form');
	},

	bindSelect2: function() {
		let select2_options = {
			placeholder: "-",
			width: '\'style\'',
			theme: 'default select2BigSelect',
			//containerCssClass: "select2BigSelect",
			dropdownCssClass: "select2BigSelect__dropdown",
			selectionCssClass: "select2BigSelect__selection"
		};
		this.ui.$selectTitles.select2(select2_options);
		this.ui.$selectCategories.select2(select2_options);
		this.ui.$selectReports.select2(select2_options);
	},
	
	bindEvents: function() {
		this.ui.$selectTitles.on('change', this.loadPage.bind(this));
		this.ui.$selectCategories.on('change', this.loadPage.bind(this));
		this.ui.$selectReports.on('change', this.loadPage.bind(this));

		this.ui.$selectZone.on('change', this.loadPage.bind(this));
		this.ui.$selectFieldType.on('change', this.loadPage.bind(this));
		
		this.ui.$print.on('click', this.print.bind(this));
		
		this.ui.$reportPanel_toggle.on('click', this.togglePanel.bind(this));

		this.ui.$tableFilter.on('click', this.tableFilter.bind(this));
	},

	loadPage: function(e) {
		let $this = $(e.currentTarget);
		window.location = $this.val();
	},

	print: function() {
		window.print();
	},

	togglePanel: function() {
		if (this.ui.$reportPanel.hasClass('reportPanel--closed')) {
			this.ui.$reportPanel
				.removeClass('reportPanel--closed')
				.addClass('reportPanel--opened');
		} else {
			this.ui.$reportPanel
				.addClass('reportPanel--closed')
				.removeClass('reportPanel--opened');
		}
	},

	tableFilter: function(e) {
		let $this = $(e.currentTarget);
		let $thisContainer = $this.closest('.js-report-tableFilter-container');
		
		if ($this.hasClass('is-active')) {
			return false;
		}

		$thisContainer.find('.js-report-tableFilter').removeClass('is-active');
		$this.addClass('is-active');

		let datas;
		if ($('.js-report-tableFilter-datas.is-active').length > 0) {
			datas = $('.js-report-tableFilter-datas.is-active').attr('data-table-filter');
		} else {
			datas = 'month';
		}
		let evol = $('.js-report-tableFilter-evol.is-active').attr('data-table-filter');
		// datas : month / accumulation
		// evol : percent / value
		
		// Update export excel form button action url to export the data we filtered
		let form_action = this.ui.$exportExcel_form.attr('action');
		let new_form_action = form_action.replace(/(datas_period=).*?(&|$)/, '$1' + datas + '$2');
		this.ui.$exportExcel_form.attr('action', new_form_action);

		// Update the table with the datas type filter
		$('.js-report-filter-datas').removeClass('is-active').addClass('hidden');
		$('.js-report-filter-datas-'+datas).addClass('is-active').removeClass('hidden');

		// Update the table with the evol type filter
		let $evol_data = $('.js-report-filter-evol-'+evol);
		$evol_data.removeClass('hidden').siblings().addClass('hidden');

		$evol_data.each((index, element) => {
			let $lineContainer = $(element).parent();
			if ($lineContainer.hasClass('js-sortableTable-contentLineCell')) {
				$lineContainer.attr('data-sortable-num', $(element).attr('data-real-number'));
			}
		});

		sortableTable.sortTable($('.js-sortableTable'));
	}
};

module.exports = report;