import { createBrowserHistory } from '../../../gulp/node_modules/history';
let history = createBrowserHistory();


const keyNumbersList = {

	ui: {},

    init: function() {
		this.bindUI();
		this.bindEvents();
        this.initKeyNumbersSlider();
		this.initiateFilter();
    },

	bindUI: function() {
		this.ui.$window = $(window);
		this.ui.$selects = $('.js-publicationContainer-select');
		this.ui.$keyNumbersSlider = $('.js-keynumbers-slider');
		this.ui.$publicationListFilter = $('.js-publicationContainer-select');
	},
	

	bindEvents: function() {
		this.ui.$publicationListFilter.on('change', this.filterElements.bind(this));
	},

	    initKeyNumbersSlider: function() {
		const keyNumbersSlider = new Swiper('.js-keynumbers-slider', {
			speed: 650,
			spaceBetween: 25,
			init: false,
			initialSlide: 0,
			slidesPerView: 'auto',
			centeredSlides: false,
			loop: ($('.js-keynumbers-slider-slide').length > 3),
			autoplay: {
				delay: 4650,
				disableOnInteraction: false
			},
			containerModifierClass: 'js-keynumbers-slider',
			wrapperClass: 'js-keynumbers-slider-wrapper',
			slideClass: 'js-keynumbers-slider-slide',
			slideActiveClass: 'active',
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				bulletActiveClass: 'active',
				type: 'bullets',
				bulletClass: 'swiper-bullet',
				renderBullet: function (index, className) {
					return '<button class="' + className + '"><span class="swiper-count swiper-count--keynumber-swiper">' + (index + 1) + '<span class="swiper-progress swiper-progress--keynumber-swiper"></span></span></span></button>';
				}
			},
			breakpoints: {
				// when window width is >= 980
				980: {
					initialSlide: 0,
					centeredSlides: false,
					spaceBetween: 50,
				}
			},
			on: {
				init: function () {
					this.slideTo(0);
				},
			}
		});
		imagesLoaded( $('.js-keynumbers-slider'), () => {
			keyNumbersSlider.init();
		});
		keyNumbersSlider.on('slideChange', function () {
			keyNumbersSlider.params.autoplay.delay = 4000;
		});
	},

	initiateFilter: function() {
		const allSelect = document.querySelectorAll('.js-publicationContainer-select');
		$(allSelect).change();		
	},

	filterElements: function(event) {
		const select = event.currentTarget;
		const publicationContainer = select.closest('.js-publicationContainer');
		const publicationRepeater = publicationContainer.querySelector('.js-publicationContainer-repeater');
		const publicationsElements = publicationRepeater.querySelectorAll('.js-publicationContainer-repeater-element');
		$(publicationsElements).addClass('publicationItem--hidden');
		$(publicationRepeater).find('.js-publicationContainer-repeater-element[data-year='+select.value+']').removeClass('publicationItem--hidden');
	}
};

module.exports = keyNumbersList;