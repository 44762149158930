const membersList = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$document = $(document);
		
		this.ui.$tooltip = $('.js-svgMapRegion-tooltip');
		this.ui.$region = $('.js-svgMapRegion-region');
		
		this.ui.$memberItem = $('.js-memberItem');
		this.ui.$letterBlock = $('.js-memberList-letterBlock');
	},

	bindEvents: function() {
		this.ui.$region.on('mouseenter focus', this.showTooltip.bind(this));
		this.ui.$region.on('mouseleave blur', this.hideTooltip.bind(this));
		this.ui.$document.on('mousemove', this.trackTooltipPosition.bind(this));
		
		this.ui.$region.on('click', this.toggleRegion.bind(this));
	},

	showTooltip: function(e) {
		let $this = $(e.currentTarget);
		
		this.ui.$tooltip.addClass('is-active');
		this.ui.$tooltip.html($this.attr('data-name'));
	},

	hideTooltip: function() {
		this.ui.$tooltip.removeClass('is-active');
	},

	trackTooltipPosition: function(e) {
		this.ui.$tooltip.css({
			left:  e.pageX,
			top:   e.pageY - 85
		});
	},
	
	toggleRegion: function(e) {
		let $this = $(e.currentTarget);
		
		if ($this.hasClass('is-active')) {
			$this.removeClass('is-active');
		} else {
			this.ui.$region.removeClass('is-active');
			$this.addClass('is-active');
		}

		this.filterMemberList();
	},
	
	filterMemberList: function(e) {
		let $activeRegion = $('.js-svgMapRegion-region.is-active');
		
		if ($activeRegion.length === 0) {
			// Filter disabled so we show everything
			this.ui.$memberItem.removeClass('is-hidden');
			this.rebuildLettersMasonry();
			
			return false;
		}
		
		let region = $activeRegion.attr('data-region');
		let department = $activeRegion.attr('data-associated-department').split(',');

		// Hide by default all member item
		this.ui.$memberItem.addClass('is-hidden');

		// Crawl each member item and check if we display them
		this.ui.$memberItem.each((index, element) => {
			let $memberItem = $(element);
			let loc_type = $memberItem.attr('data-localisation-type');
			
			if (loc_type === 'all') {
				$memberItem.removeClass('is-hidden');
			} else if (loc_type === 'region') {
				let loc_region = $memberItem.attr('data-localisation-region').split(',');
				if (loc_region.indexOf(region) !== -1) {
					$memberItem.removeClass('is-hidden');
				}
			} else if (loc_type === 'department') {
				let loc_department_list = $memberItem.attr('data-localisation-department').split(',');
				let displayThisMember = false;
				loc_department_list.forEach((loc_department) => {
					if (department.indexOf(loc_department) !== -1) {
						displayThisMember = true;
					}
				});
				if (displayThisMember) {
					$memberItem.removeClass('is-hidden');
				}
			}
		});

		this.rebuildLettersMasonry();
	},

	rebuildLettersMasonry: function() {
		// Crawl each letter block if they are empty, if so they're hidden
		this.ui.$letterBlock.each((index, element) => {
			let $letterBlock = $(element);
			let total_member = $letterBlock.find('.js-memberItem').length;
			let total_member_hidden = $letterBlock.find('.js-memberItem.is-hidden').length;

			if (total_member === total_member_hidden) {
				$letterBlock.addClass('is-hidden');
			} else {
				$letterBlock.removeClass('is-hidden');
			}
		});

		// Rebuild the masonry to avoid blank spaces
		let memberListMasonry = Masonry.data( $('.members--list-main')[0] );
		memberListMasonry.layout();
	}
};

module.exports = membersList;