const blockLastNews = {

	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.lastNewsSlider();
	},

	bindUI: function() {
		this.ui.$window = $(window);

		this.ui.$lastNewsSlider = $('.js-lastNewsSlider');
	},

	bindEvents: function() {
	},

	lastNewsSlider: function() {
		this.ui.$lastNewsSlider.Swiper({
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 5
			},
			speed: 400,
			spaceBetween: 30,
			containerModifierClass: 'js-lastNewsSlider',
			wrapperClass: 'js-lastNewsSlider-wrapper',
			slideClass: 'js-lastNewsSlider-slide',
			slideActiveClass: 'active',
			slidesPerView: 'auto',
			freeModeSticky: true,
			scrollbar: {
				el: '.js-lastNewsSlider-scrollbar',
				draggable: true,
				dragClass: 'lastNewsSlider__scrollbar-drag',
				dragSize: 100
			},
			breakpoints: {
				// when window width is >= 980
				980: {
					//autoHeight: false
				}
			}
		});
	}

};

module.exports = blockLastNews;