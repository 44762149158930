// Import Modules
import jQuery from "jquery";
import bridget from "../../gulp/node_modules/jquery-bridget";
import imagesLoaded from "../../gulp/node_modules/imagesloaded";
import Swiper from "../../gulp/node_modules/swiper";
import Masonry from "../../gulp/node_modules/masonry-layout";


// export for others scripts to use
window.$ = window.jQuery = jQuery;
window.imagesLoaded = imagesLoaded;
window.Swiper = Swiper;
window.Masonry = Masonry;

$.bridget( 'masonry', Masonry, $ );
$.bridget( 'imagesLoaded', imagesLoaded, $ );
$.bridget( 'Swiper', Swiper, $ );

// Require modules
import tooltip from './modules/tooltip'
import slidingTitles from './modules/slidingTitles.js'
import newsList from './modules/newsList.js'
import newsSingle from './modules/newsSingle.js'
import blockSlider from './modules/blockSlider.js'
import blockLastNews from './modules/blockLastNews.js'
import popin from './modules/popin.js'
import timelineToggle from './modules/timelineToggle.js'
import documentsList from './modules/documentsList.js'
import timeline from './modules/timeline.js'
import membersTiles from './modules/membersTiles.js'
import newsAdherentList from './modules/newsAdherentList.js'
import manageTitles from './modules/manageTitles.js'
import manageTitles_collect from './modules/manageTitles_collect.js'
import report from './modules/report'
import report_chart from './modules/report_chart'
import sortableTable from './modules/sortableTable'
import membersList from './modules/membersList'
import singleClaim from './modules/singleClaim'
import keyNumbersList from './modules/keyNumbersList'

// Global values
let ua = navigator.userAgent.toLowerCase();
window.isSafari = (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);

// Global calcuation of evolution
window.calcEvol = function(current_value, previous_value) {
	let calc = (( current_value - previous_value ) / previous_value) * 100;
	return Math.round(calc) + ' %';
};

// Polyfill "Object.entries"
if (!Object.entries) {
	Object.entries = function( obj ){
		let ownProps = Object.keys( obj ),
			i = ownProps.length,
			resArray = new Array(i);
		while (i--)
			resArray[i] = [ownProps[i], obj[ownProps[i]]];

		return resArray;
	};
}

$(function(){
	$("html").removeClass("no-js");


	if ($(".js_tooltip").length) {
		tooltip.init();
	}

	if ($(".js-sliding-titles").length) {
		slidingTitles.init();
	}

	if ($(".js-news-slider").length) {
		newsList.init();
	}

	if ($(".js-keynumbers-slider").length) {
		keyNumbersList.init();
	}

	if ($(".js-news-single").length) {
		newsSingle.init();
	}

	if ($(".js-block-slider").length) {
		blockSlider.init();
	}

	if ($(".js-lastNewsSlider").length) {
		blockLastNews.init();
	}

	if ($(".js_popin").length) {
		popin.init();
	}

	if ($(".js-timelineToggle-toggle").length) {
		timelineToggle.init();
	}

	if ($(".js-documentsList").length) {
		documentsList.init();
	}
	
	if ($(".js-timeline").length) {
		timeline.init();
	}
	
	if ($(".js-membersTiles").length) {
		membersTiles.init();
	}
	
	if ($(".js-newsAdherentList").length) {
		newsAdherentList.init();
	}

	if ($(".js-manageTitles-selectTitles").length || $(".js-manageTitles-selectCollects").length) {
		manageTitles.init();
	}
	if ($(".js-manageTitles-collect").length) {
		manageTitles_collect.init();
	}
	
	if ($(".js-report-selectReports").length) {
		report.init();
	}
	
	if ($(".js-report").length) {
		report_chart.init();
	}
	
	if ($(".js-sortableTable").length) {
		sortableTable.init();
	}
	
	if ($(".js-svgMapRegion").length) {
		membersList.init();
	}
	
	if ($(".js-claim").length) {
		singleClaim.init();
	}


	/***********************************
	 MAIN
	***********************************/
	var mainHandler = {

		init: function(){
			
			//this.stickyNav();
			this.mobileMenu();

			// Prevent Menu # anchor
			$('.nav--main a[href="#"]').on('click', function(e){
				e.preventDefault();
			});
		},

		stickyNav: function(){
			
			// Sticky Header
			var previousOffset = 0,
				$header = document.querySelector('.header--main'),
				$headerHeight = $header.offsetHeight;
			
			window.addEventListener('scroll', function () {

				var currentOffset = document.documentElement.scrollTop || document.body.scrollTop;

				if (currentOffset > previousOffset){

					// downscroll code

					if( currentOffset > $headerHeight ){
						$header.classList.add('scrolling');
					}

				} else {
					// upscroll code

				}

				if( currentOffset === 0 ){
					$header.classList.remove('scrolling');
				}

				previousOffset = currentOffset;

			});
			
		},

        mobileMenu: function(){

			var $viewportWidth = mainHandler.getViewportWidth(),
				$menuButton = document.querySelector('.js-navToggle'),
				$NavWrap = document.querySelector('.js-navWrap');

			window.addEventListener('resize', function () {
				$viewportWidth = mainHandler.getViewportWidth();
			}, false);

            // Mobile Nav
            $menuButton.addEventListener('click', function(){
				/* Toggle Button */
				if( !this.classList.contains('opened') ){
					this.classList.add('opened');
					this.classList.remove('closed');
				} else {
					this.classList.add('closed');
					this.classList.remove('opened');
				}

				/* Toggle Main menu */
				if( !$NavWrap.classList.contains('opened') ){
					$NavWrap.classList.add('opened');
				} else {
					$NavWrap.classList.remove('opened');
				}
			});

        },

		getViewportWidth: function(){
			return (window.innerWidth || document.documentElement.clientWidth);
		},

		isScrolledIntoView: function($elem, $window){
			var docViewTop = $window.scrollTop();
			var docViewBottom = docViewTop + $window[0].innerHeight;

			var elemTop = $elem.offset().top;
			var elemBottom = elemTop + $elem.height();

			return ((docViewBottom > elemTop) && (docViewTop < elemBottom));
		},

		toggleHeaderLayout: function($layout){
			var $header = document.querySelector('.header--main');			
			switch ($layout){
				case 'lt':
				$header.classList.remove('dk');
				$header.classList.remove('transparent-lt');
				$header.classList.add('lt');
				break;
				case 'transparent-lt':
				$header.classList.remove('dk');
				$header.classList.remove('lt');
				$header.classList.add('transparent-lt');
				break;
				case 'dk':
				$header.classList.remove('lt');
				$header.classList.remove('transparent-lt');
				$header.classList.add('dk');
				break;
			}

		}

	};
	mainHandler.init();
	
	/***********************************
	 HOME
	***********************************/
	var homeHandler = {

		init: function(){
			this.heroSlider();
			if(document.querySelector('.section--latests-news')){
				this.newsSlider();
				// Animations
				this.animFeaturedPost();
			}
			
			this.twitterTimeline();			
		},

		heroSlider: function(){
			
			var $heroSlider = new Swiper('.slider-hero--container', {
				preloadImages: false,
				lazy: true,
				speed: 650,
				spaceBetween: 0,
				loop: true,
				autoplay: {
					delay: 4650,
					disableOnInteraction: false
				},
				containerModifierClass: 'slider-hero--container',
				wrapperClass: 'slider-hero--wrapper',
				slideClass: 'slider-hero--slide',
				slideActiveClass: 'active',
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					bulletActiveClass: 'active',
					type: 'bullets',
					bulletClass: 'swiper-bullet',
					renderBullet: function (index, className) {
						return '<button class="' + className + '"><span class="swiper-count">' + (index + 1) + '<span class="swiper-progress"></span></span></span></button>';
					}
				},
			});
			$heroSlider.on('slideChange', function () {
				$heroSlider.params.autoplay.delay = 4000;
			});
		},

		animFeaturedPost: function(){
			
			var $fired = 0,
				$window = $(window),
				$featuredPost = $(".featured-post");

            window.addEventListener('scroll', function () {
                if (mainHandler.isScrolledIntoView($featuredPost, $window) && $fired === 0) {
					$featuredPost[0].classList.add('show');
                    $fired = 1;
                } else if( !mainHandler.isScrolledIntoView($featuredPost, $window) ) {
					//$featuredPost[0].classList.remove('show');
                    $fired = 0;
                }
			});

		},

		newsSlider: function(){
			var $breakpoint = 1280,
				$viewportWidth = mainHandler.getViewportWidth(),
				$sectionNews = document.querySelector('.section--latests-news'),
				$scrollBar = document.createElement('div'),
				setScrollbar = function () {
					// Create Scrollbar
					$scrollBar.classList.add('slider-news--scrollbar');
					// Remove previous Scrollbar
					if( document.querySelector('.slider-news--scrollbar') != null ){
						document.querySelector('.slider-news--scrollbar').parentNode.removeChild($scrollBar);
					}

					// Set Scrollbar
					if ($viewportWidth >= $breakpoint) {
						$sectionNews.querySelector('.section--head-title').parentNode.appendChild($scrollBar);
					} else {
						$sectionNews.querySelector('.slider-news--wrapper').parentNode.appendChild($scrollBar);
					}
				};

				setScrollbar();
	
				// On resize events, recalculate and log
				window.addEventListener('resize', function () {
					$viewportWidth = mainHandler.getViewportWidth();
					setScrollbar();
				}, false);

			
			
			var $newsSlider = new Swiper('.slider-news--container', {
				speed: 400,
				spaceBetween: 10,
				containerModifierClass: 'slider-news--container',
				wrapperClass: 'slider-news--wrapper',
				slideClass: 'slider-news--slide',
				slideActiveClass: 'active',
				slidesPerView: 'auto',
				breakpoints: {
					$breakpoint: {
						spaceBetween: 30
					}
				},
				scrollbar: {
					el: '.slider-news--scrollbar',
					draggable: true,
					dragClass: 'slider-news--scrollbar-drag',
					dragSize: 130
				}
			});
		},

		twitterTimeline: function(){
			
			var $breakpoint = 1024,
				viewportWidth = mainHandler.getViewportWidth(),
				$sliderTwitterTimeline = document.querySelector('#twitter-timeline'),
				$slides = $sliderTwitterTimeline.innerHTML,
				$masonryTwitter,
				$masonryGutter = document.createElement('div'),
				$masonrySizer = document.createElement('div'),
				$masonryTwitterOptions = {
					itemSelector: '.card--tweet',
					gutter: '.tw-gutter',
					//horizontalOrder: true,
					columnWidth: '.tw-sizer',
					//fitWidth: true,
					percentPosition: true,
					initLayout: false,
					transitionDuration: 0
				};

            function setSlider() {
                if (viewportWidth >= $breakpoint) {
                    if( $sliderTwitterTimeline.className.match(/containerinitialized/g) ){
                        destroySlider();
                    }

                    if(!$sliderTwitterTimeline.className.match(/masonry/g)){
						// Create Masonry
						$masonrySizer.classList.add('tw-sizer');
						document.querySelector('.twitter-feed--wrap').prepend($masonrySizer);

						$masonryGutter.classList.add('tw-gutter');
						document.querySelector('.twitter-feed--wrap').prepend($masonryGutter);

						$masonryTwitter = new Masonry( '.twitter-feed--wrap', $masonryTwitterOptions );
						$masonryTwitter.on('layoutComplete', function(){
							$sliderTwitterTimeline.classList.add('masonry');
						});
						imagesLoaded( $sliderTwitterTimeline, function() {
							$masonryTwitter.layout();
							//$masonryTwitter.reloadItems();
						}).on( 'done', function() {
							$masonryTwitter.layout();
						});
					}
                } else {
					if( !$sliderTwitterTimeline.className.match(/containerinitialized/g) ){
						initSlider();
					}
                    
                }
            }

			// Set our initial width and log it
			//setViewportWidth();

			setTimeout(function(){
				setSlider();
			}, 500);



			// On resize events, recalculate and log
			window.addEventListener('resize', function () {
				viewportWidth = mainHandler.getViewportWidth();
				setSlider();
			}, false);

			function initSlider(){
				
				// Destroy Masonry
				if( $sliderTwitterTimeline.classList.contains('masonry') ){
					destroyMasonry();
				}

				// Set Swiper Container class
				$sliderTwitterTimeline.classList.add('slider-twitter--container');
				
				// Wrap Slides
				var $wrappedSlides = '<div class="slider-twitter--wrapper">' + $slides + '</div>';
				var $scrollBar = '<div class="slider-twitter--scrollbar"></div>';
				$sliderTwitterTimeline.innerHTML = $wrappedSlides + $scrollBar;

                var $swiperTimeline = new Swiper('.slider-twitter--container', {
					speed: 400,
					spaceBetween: 15,
					slidesPerView: 'auto',
					//loop: true,
					freeModeSticky: true,
					containerModifierClass: 'slider-twitter--container',
					wrapperClass: 'slider-twitter--wrapper',
					slideClass: 'card--tweet',
					slideActiveClass: 'active',
					scrollbar: {
						el: '.slider-twitter--scrollbar',
						draggable: true,
						dragClass: 'slider-twitter--scrollbar-drag',
						dragSize: 130
					}
				});


                

				//$('#slider-projects .slider-navigation').removeClass('hidden');

			}

			// For the Twitter Timeline, the Slider became a Masonry on Desktop
			function destroySlider(){
				// Destroy Swiper
				if (!(typeof $swiperTimeline === 'undefined' || $swiperTimeline === null)) {
					$swiperTimeline.destroy(true,true);
				}

				// Remove Classes
				$sliderTwitterTimeline.classList = 'twitter-feed--wrap';

				// Reset Wrap Slides
				$sliderTwitterTimeline.innerHTML = $slides;

				//$('#slider-projects .slider-navigation').addClass('hidden');
				
			}

			function destroyMasonry(){
				//alert('destroyMasonry');
				setTimeout(function(){
					$masonryTwitter.destroy();
					$sliderTwitterTimeline.classList.remove('masonry');
				},150);
			}
			
		}

	};
    if($('#home').length){
        homeHandler.init();
	}

    var $swiperClaim = new Swiper('.js-swiper-container', {
        speed: 400,
        spaceBetween: 0,
        slidesPerView: 'auto',
        //loop: true,
        freeModeSticky: true,
        containerModifierClass: 'js-swiper-container',
        wrapperClass: 'js-swiper-wrapper',
        slideClass: 'js-swipe',
        slideActiveClass: 'active',
        scrollbar: {
            el: '.js-swiper-scrollbar',
            draggable: true,
            dragClass: 'js-swiper-scrollbar-drag',
            dragSize: 130
        }
    });
	

	/***********************************
	 MEMBRES (LIST)
	***********************************/
	var membersHandler = {

		init: function(){

			var self = this,
				$viewportWidth = mainHandler.getViewportWidth();
			
			self.mobileHelpers($viewportWidth);

			window.addEventListener('resize', function () {
				$viewportWidth = mainHandler.getViewportWidth();
				self.mobileHelpers($viewportWidth);
			}, false);
			
			self.setIndexMasonry();
			self.setMembersFilters();
		},

		mobileHelpers: function($viewportWidth){

			// Set Index to .
			if ($viewportWidth < 1024) {
				$('.members--list-index').appendTo('.members--list-wrap');
			} else {
				$('.members--list-index').appendTo('.members--list-head');
			}
			
		},

		setIndexMasonry: function(){

			var $breakpoint = 600,
				$viewportWidth = mainHandler.getViewportWidth(),
				$masonryOptions = {
					itemSelector: '.members--list-alphablock',
					gutter: '.mb-gutter',
					horizontalOrder: true,
					columnWidth: '.mb-sizer',
					//fitWidth: true,
					percentPosition: true,
					initLayout: false,
					transitionDuration: 0
				};
			
			setMasonry($viewportWidth);
			
			window.addEventListener('resize', function () {
				$viewportWidth = mainHandler.getViewportWidth();
				setMasonry($viewportWidth);
			}, false);

			function setMasonry($viewportWidth){
				var $masonryMembers = new Masonry( '.members--list-main', $masonryOptions );
				if ($viewportWidth >= $breakpoint) {
					$masonryMembers.layout();
				} else {
					$masonryMembers.destroy();
				}
			}

		},

		setMembersFilters: function(){

			var $currentFilter = $('.members--filters-current'),
				$choices = $('.members--filters-choices li');

			$currentFilter.on('click', function(){
				if(!$('.members--filters').hasClass('expanded')){
					openFilters();
				} else {
					closeFilters();
				}
			});

			$(document).on('click', function(e){
				if( !$(e.target).is('.members--filters') && $('.members--filters').has(e.target).length === 0 ){
					//closeModal();
					$('.members--filters').removeClass('expanded')
					mainHandler.toggleHeaderLayout('dk');
				}
			});

			$choices.on('click', function(){
				var $choiceLabel = $(this).text();
				$('.members--filters-choices li').removeClass('hidden');
				$(this).addClass('hidden');
				$('.members--filters-current').text($choiceLabel);
				closeFilters();
			});

			function openFilters(){
				$('.members--filters').addClass('expanded');
				//mainHandler.toggleHeaderLayout('lt');
				mainHandler.toggleHeaderLayout('transparent-lt');
			}

			function closeFilters(){
				$('.members--filters').removeClass('expanded');
				mainHandler.toggleHeaderLayout('dk');
			}
		}

	};
    if($('.members--list-alphablock').length){
        membersHandler.init();
	}


	/***********************************
	 MEMBRES (LIST)
	***********************************/
	var missionsHandler = {

		init: function(){
			this.missionsCards()
		},

		missionsCards: function(){

			var $card = $('.card--mission');
			$card.on('click', function(e){
				e.preventDefault();
				var $section = $(this).attr('href'),
					$offset = $($section).offset().top;
					
					fixOffset();
					window.addEventListener('resize', function () {
						fixOffset();
					}, false);

					function fixOffset(){
						$offset = mainHandler.getViewportWidth() < 980 ? $offset - $('.header--main').outerHeight(true) : $offset - 40;
					}

					$('html, body').animate({scrollTop: $offset}, 400);

			});
		}

	};
    if($('#missions').length){
        missionsHandler.init();
	}

});
