const report_chart = {
	
	ui: {},

	init: function() {
		this.bindUI();
		
		//this.bindChart();
		
		this.bindEvents();
	},

	bindUI: function() {
		//this.ui.$chart = $('.js-report-chart');
	},

	bindChart: function() {
		// Create chart instance
		let chart = am4core.create("js-report-chart", am4charts.PieChart);

		// Create pie series
		let series = chart.series.push(new am4charts.PieSeries());
		series.dataFields.value = "litres";
		series.dataFields.category = "country";

		// Add data
		chart.data = [{
			"country": "Lithuania",
			"litres": 501.9
		}, {
			"country": "Czech Republic",
			"litres": 301.9
		}, {
			"country": "Ireland",
			"litres": 201.1
		}, {
			"country": "Germany",
			"litres": 165.8
		}, {
			"country": "Australia",
			"litres": 139.9
		}, {
			"country": "Austria",
			"litres": 128.3
		}, {
			"country": "UK",
			"litres": 99
		}, {
			"country": "Belgium",
			"litres": 60
		}, {
			"country": "The Netherlands",
			"litres": 50
		}];

		// And, for a good measure, let's add a legend
		chart.legend = new am4charts.Legend();
	},
	
	bindEvents: function() {
		
	}
};

module.exports = report_chart;