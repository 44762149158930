const popin = {

	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$html = $('html');
		this.ui.$document = $(document);

		this.ui.$displayPopin = $(".js_popin_display");
		this.ui.$popin = $(".js_popin");
		this.ui.$popinClose = $(".js_popin_close");
		this.ui.$popinContainer = $('.js_popin_container');
	},

	bindEvents: function() {
		this.ui.$displayPopin.on('click', this.popinOpen.bind(this));
		this.ui.$popinClose.on('click', this.popinClose.bind(this));
		this.ui.$document.on('click', this.popinClose.bind(this));
	},

	popinOpen: function(e) {
		e.stopPropagation();
		e.preventDefault();
		let $this = $(e.target);
		let $target_popin = $(".js_popin_"+$this.attr('data-popin-target'));
		
		if (!$target_popin.hasClass('is-active')) {
			this.ui.$html.addClass('disableScroll');
			$target_popin.fadeIn(200).addClass('is-active');
		}
	},

	popinClose: function(e) {
		e.stopPropagation();
		let $this = $(e.target);
		let $activePopin = $(".js_popin.is-active");
		
		if ($activePopin.length > 0) {
			if (!$(".js_popin.is-active .js_popin_container")[0].contains(e.target) || $this.hasClass('js_popin_close')) {
				this.ui.$html.removeClass('disableScroll');
				$activePopin.fadeOut(200).removeClass('is-active');
			}
		}
	}
};

module.exports = popin;
