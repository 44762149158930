const singleClaim = {

	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.stickyMenu();
	},

	bindUI: function() {
		this.ui.$window = $(window);
		this.ui.$claims = $('.js-claim-body');
		this.ui.$menu = $('.js-claim-menu');
		this.ui.$menuItem = $('.js-claim-menu-item');
		this.ui.$section = $('.js-claim-section');
	},

	bindEvents: function() {
		this.ui.$window.on('scroll', this.stickyMenu.bind(this))
        this.ui.$menuItem.on('click', this.menuScrollTo.bind(this))
	},

	stickyMenu: function() {
		let scroll = this.ui.$window.scrollTop()
        let claimsTop = this.ui.$claims.offset().top
        let claimsBottom = claimsTop + this.ui.$claims.outerHeight()
        let offset = 150
        if (scroll > (claimsTop - offset)) {
            this.ui.$menu.addClass('is-sticky')
        } else {
            this.ui.$menu.removeClass('is-sticky')
        }
        if (scroll > (claimsBottom - offset - offset)) {
            this.ui.$menu.addClass('is-bottom')
        } else {
            this.ui.$menu.removeClass('is-bottom')
        }
        this.ui.$section.each((i, e) => {
            let scroll = this.ui.$window.scrollTop()
            let offset = 150
            let sectionTop = $(e).offset().top
            let sectionBottom = sectionTop + $(e).outerHeight()
            if ((scroll + offset) > sectionTop && (scroll + offset) < sectionBottom) {
                let $currentItem = $('.js-claim-menu-item').filter((j, m) => $(m).attr('data-target') === $(e).attr('id'))
                if ($currentItem.length > 0 && $currentItem.hasClass('is-active') == false) {
                    this.ui.$menuItem.removeClass('is-active')
                    $currentItem.addClass('is-active')
                }
                return false
            }
        })
	},

	menuScrollTo: function(e) {
        e.preventDefault()
        let $this = $(e.currentTarget)
        let target = $this.attr('data-target')
        let top = $('#' + target).offset().top - 32
        $([document.documentElement, document.body]).animate({
            scrollTop: top
        }, 500);
	}
};

module.exports = singleClaim;