import { createPopper } from '@popperjs/core';

const tooltip = {

	ui: {},

	popperInstance: [],

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$tooltip = $('.js_tooltip');
		this.ui.$tooltipButton = $('.js_tooltip_button');
	},

	bindEvents: function() {
		this.ui.$tooltipButton.on('mouseenter focus', this.show.bind(this));
		this.ui.$tooltipButton.on('mouseleave blur', this.hide.bind(this));
	},

	create: function(tooltip_index, $tooltip_button, $tooltip) {
		this.popperInstance[tooltip_index] = createPopper($tooltip_button, $tooltip, {
			placement: 'right',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 8],
					},
				},
			],
		});
	},

	destroy: function(tooltip_index) {
		if (this.popperInstance[tooltip_index]) {
			this.popperInstance[tooltip_index].destroy();
			this.popperInstance[tooltip_index] = null;
		}
	},

	show: function(e) {
		let $tooltip_button = $(e.currentTarget)[0];
		let $tooltip = $(e.currentTarget).next()[0];
		let tooltip_index = $tooltip_button.getAttribute('data-tooltip-index');

		$tooltip.setAttribute('data-show', '');
		this.create(tooltip_index, $tooltip_button, $tooltip);
	},

	hide: function(e) {
		let $tooltip_button = $(e.currentTarget)[0];
		let $tooltip = $(e.currentTarget).next()[0];
		let tooltip_index = $tooltip_button.getAttribute('data-tooltip-index');

		$tooltip.removeAttribute('data-show');
		this.destroy(tooltip_index);
	}
};

module.exports = tooltip;
